<template>
  <div>
    <div class="companydesc" :style="{ height: height + 'px' }">
      <img :src="descData.imgUrl" alt="" />
      <div class="desc">
        <div
          class="descMain"
          :style="{ height: descHeight + 'px', width: descWidth + 'px' }"
        >
          <div>{{ descData.title }}</div>
          <div v-if="descData.descOne" class="descOne">
            {{ descData.descOne }}
          </div>
          <div class="line"></div>
          <div v-if="descData.descTwo" class="descTwo">
            {{ descData.descTwo }}
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GmPortalIndex",
  props: {
    height: {
      type: String,
      default: () => "452",
    },
    descData: {
      type: Object,
      default: () => {},
    },
    descWidth: {
      type: String,
      default: () => "",
    },
    descHeight: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.companydesc {
  width: 1200px;
  img {
    width: 760px;
    height: 100%;
    float: left;
  }
  .desc {
    width: 440px;
    height: 100%;
    background: rgba(245, 245, 245, 1);
    float: left;
    position: relative;
    .descMain {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      div:nth-child(1) {
        height: 24px;
        font-size: 24px;
        line-height: 24px;
        color: rgba(234, 144, 82, 1);
        font-weight: bold;
      }
      .descOne {
        width: 368px;
        height: 100px;
        font-size: 14px;
        line-height: 26px;
        font-family: Noto Sans S Chinese-DemiLight, Noto Sans S Chinese;
        font-weight: normal;
        color: rgba(102, 102, 102, 1);
        line-height: 26px;
        margin-top: 20px;
      }
      .line {
        width: 360px;
        height: 1px;
        border-top: 1px solid rgba(102, 102, 102, 1);
        /* margin: px 0; */
        margin-top: 24px;
      }
      .descTwo {
        margin-top: 20px;
        width: 364px;
        height: 194px;
        font-size: 14px;
        font-family: Noto Sans S Chinese-DemiLight, Noto Sans S Chinese;
        font-weight: normal;
        color: #666666;
        line-height: 20px;
      }
    }
  }
}
</style>
