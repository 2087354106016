<template>
  <div>
    <div class="deal">
      <div class="banner">
        <div class="head">
          <div class="title">数字化交易技术</div>
          <div class="btn" @click="goAbout">方案咨询</div>
        </div>
      </div>
      <div class="main">
        <Description
          height="360"
          :descData="descData"
          descHeight="200"
          descWidth="360"
        ></Description>
      </div>
    </div>
  </div>
</template>

<script>
import Description from "@/components/desc";
export default {
  components: { Description },
  data() {
    return {
      descData: {
        imgUrl: require("@/assets/main/detal/logo.webp"),
        title: "数字化交易技术",
        descTwo:
          "数字化交易技术是面向贵金属上下游产业中金料流通的业务运营平台，为贵金属产业中各类参与主体企业（含相关业务人员）提供实名认证及记账服务，通过和各环节第三方业务系统对接为企业生产经营提供金料买入、借入周转、结算、存入、提料等支撑能力。",
      },
    };
  },

  mounted() {},

  methods: {
    goAbout() {
      this.$router.push({ path: "/about", query: { ask: "true" } });
    },
  },
};
</script>

<style lang="scss" scoped>
.deal {
  /* width: 100vw; */
  height: 840px;
  .banner {
    height: 400px;
    background: url("../../assets/main/detal/banner.png") no-repeat;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    .head {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .title {
        width: 224px;
        height: 32px;
        line-height: 22px;
        font-size: 32px;
        font-weight: bold;
        color: rgba(234, 144, 82, 1);
        margin: 0 auto;
        margin-top: 144px;
        margin-bottom: 40px;
      }
      .btn {
        width: 134px;
        height: 40px;
        background: rgba(234, 144, 82, 1);
        border-radius: 6px;
        text-align: center;
        line-height: 40px;
        font-size: 11px;
        color: rgba(255, 255, 255, 1);
        margin: 0 auto;
        cursor: pointer;
      }
      .btn:hover {
        background: rgba(245, 166, 117, 1);
      }
    }
  }
  .main {
    width: 1200px;
    height: 440px;
    margin: 0 auto;
    margin-top: 40px;
    .content {
      width: 100%;
      height: 360px;
    }
  }
}
</style>
